<script setup>
import { ref, computed, watch, onMounted, onUnmounted, toRaw, inject } from 'vue'
import { useStore } from 'vuex'
import { useGeolocation } from '@/composables/geolocation'
import alertTypes from '@/constants/alertTypes'
import {normalizeText} from '@/utils'
import { useGoogleMaps } from '@/composables/google-maps'
import StatusBadget from '@/components/StatusBadget'

const {loader} = useGoogleMaps()

const { coords } = useGeolocation()

const mapDiv = ref(null)
const map = ref(null)
const markers = ref([])


const store = useStore()

const alerts = computed(() => {
  return store.state.alertsStore.alerts
})
watch(alerts, (newAlerts) => {
  if(alertCoords.value) return
  markers.value.forEach(marker => {
    toRaw(marker).setMap(null)
  });
  
  markers.value = [];

  setMarket(newAlerts)
})

const {alertCoords, setAlertPosition} = inject('alertCoords')

watch(alertCoords, (newCenter) => {
  if (newCenter) {
    markers.value.forEach(marker => {
      toRaw(marker).setMap(null)
    });
    
    markers.value = [];
    setSelectedMarker(newCenter)
  }
})

const setSelectedMarker = (alert) =>{
  setMarket([alert])
    // eslint-disable-next-line no-undef
    map.value.setZoom(30);
  if (allAreas.value && store.state.userStore.alertsLocation && Object.values(allAreas.value).map(item => item.id).includes(store.state.userStore.alertsLocation)) {
      clearAllShapes()
      const currentArea = store.state.areasStore.areas.find(item => item.id  == store.state.userStore.alertsLocation)
      const area = currentArea?.area
      if(!area) {
        map.value.setCenter(coords.value)
        return
      }
      const formated = formatCoords(area)
      generateShape(formated)
      return
    }
    // eslint-disable-next-line no-undef
    map.value.setCenter({lat:alert.lat,lng:alert.lng});
}

watch(coords, (newCenter, oldCenter) => {
  if (map.value && (!oldCenter.lat && !oldCenter.lng) && !alertCoords.value) {
    // eslint-disable-next-line no-undef
    if(polygons.value.length) return
    map.value.setCenter(newCenter);
  }
})

const allAreas = computed(()=>{
  return store.state.areasStore.areas
})

watch(allAreas, (newCenter) => {
  if (newCenter && store.state.userStore.alertsLocation) {
    setAreaCenter()
  }
})

const currentLocation = computed(()=>{
  return store.state.userStore.alertsLocation
})

watch(currentLocation, (newCenter) => {
  if (newCenter && store.state.userStore.alertsLocation) {
    setAreaCenter()
  }
})

const setAreaCenter = () =>{
  clearAllShapes()
  const currentArea = store.state.areasStore.areas.find(item => item.id  == store.state.userStore.alertsLocation)
  const area = currentArea?.area
  if(!area) {
    map.value.setCenter(coords.value)
    return
  }
  const formated = formatCoords(area)
  const center = calculateCenter(formated);
  map.value.setCenter(center);
  map.value.setZoom(15);
  generateShape(formated)
}

const clearAllShapes = () => {
  polygons.value.forEach(polygon => {
    // Clear all event listeners associated with the polygon
    // eslint-disable-next-line no-undef
    google.maps.event.clearInstanceListeners(polygon);
    // Remove the polygon from the map
    toRaw(polygon).setMap(null);
  });
  // Clear the array of polygons
  polygons.value = [];
};

const polygons = ref([]);
const generateShape = (coordinates) => {
  // eslint-disable-next-line no-undef
  const polygon = new google.maps.Polygon({
    paths: coordinates,
    draggable: false,
    strokeColor: '#ff0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    editable: false,
    fillColor: '#ff0000',
    fillOpacity: 0.35,
  });
  polygon.setMap(map.value);
  polygons.value.push(polygon);
};
const formatCoords = (area) => {
  const coords = area.split(' ')
  const formated = coords.map(coord => coord.replace(/[{()}]/g, '').split(',')).map(item => ({ lat: parseFloat(item[0]), lng: parseFloat(item[1]) }))
  return formated
}
const calculateCenter = (coordinates) => {
  let latSum = 0;
  let lngSum = 0;
  const len = coordinates.length;

  coordinates.forEach(coord => {
    latSum += coord.lat;
    lngSum += coord.lng;
  });

  return { lat: latSum / len, lng: lngSum / len };
};
onMounted(() => {
  loader.value.load().then(async () => {
    // eslint-disable-next-line no-undef
    const { Map } = await google.maps.importLibrary("maps");

    const center = alertCoords.value ?{lat:alertCoords.value?.lat, lng:alertCoords.value?.lng }  : coords.value

    map.value = new Map(document.getElementById('map'), {
      center: center,
      zoom: 15,
      heading: 320,
      tilt: 47.5,
    });
  }).then(() => {
    if(alertCoords.value){
      setSelectedMarker(alertCoords.value)
      return
    }
    if (allAreas.value && store.state.userStore.alertsLocation && Object.values(allAreas.value).map(item => item.id).includes(store.state.userStore.alertsLocation)) {
      setAreaCenter()
      setMarket(alerts.value)
      return
    }
    if (alerts.value.length) {
      setMarket(alerts.value)
    }
  })
})

onUnmounted(() => {
  setAlertPosition(null)
})

const seePendingAlerts = () => {
  setAlertPosition(null)
  if (alerts.value.length) {
    setMarket(alerts.value)
    if (polygons.value.length) {
      setAreaCenter()
      return
    }
    // eslint-disable-next-line no-undef
    map.value.setZoom(15);
    // eslint-disable-next-line no-undef
    map.value.setCenter(coords.value);
  }
}

const setMarket = (alerts) => {
  alerts.map(alert => {
    const contentString =`
    <div id="content">
      <div id="siteNotice">
      </div>
      <h3 id="firstHeading" class="firstHeading">${alert.codigo}</h1>
      <div id="bodyContent">
        <div><b>Fecha:</b> ${alert.fecha}</div>
        <div><b>Dirección:</b> ${alert.lugar}</div>
        <div><b>Nombre:</b> ${alert.nombre}</div>
        <div class="mb-1"><b>Teléfono:</b> ${alert.telefono}</div>
        <div class="mb-1"><b>Estado:</b> ${alert.estado}</div>
      </div>
    </div>
    `

    // eslint-disable-next-line no-undef
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
      ariaLabel: alert.codigo,
    });
    
    const type = alertTypes.find(type => normalizeText(alert.codigo) == normalizeText(type.label))
    // const estado = alert.estado
    // const icon = estado == 'PENDIENTE' ? type.icon : type.icon2
    // eslint-disable-next-line no-undef
    var image = new google.maps.MarkerImage(
      type?.icon,
    // eslint-disable-next-line no-undef
    new google.maps.Size(50, 48),
    // eslint-disable-next-line no-undef
    new google.maps.Point(0, 0),
    // eslint-disable-next-line no-undef
    new google.maps.Point(0, 0),
    // eslint-disable-next-line no-undef
    new google.maps.Size(50, 48));
    
    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
      position: { lat: alert.lat, lng: alert.lng },
      map: map.value,
      title: alert.codigo,
      icon: image
    });

    marker.addListener("click", () => {

    infowindow.open({
        anchor: marker,
        map: map.value,
      });
    });

    markers.value.push(marker)
  })
  // new MarkerClusterer({ markers: toRaw(markers.value), map: map.value });
}


</script>
<template>
  <div class="py-4 container-fluid" style="height: calc(100% - 4rem) !important;">
    <div class="card h-100 w-100 p-3">
      <div class="selected_alert_area d-flex align-items-center justify-content-between" v-if="alertCoords">
        <div class="d-flex align-items-center gap-2 fw-bold">
          Estás viendo una alerta
          <StatusBadget :estado="alertCoords.estado" :id="alertCoords.id" :readOnly="true"/>
        </div>
        <button class="btn btn-success" @click="seePendingAlerts">Ver las alertas pendientes</button>
      </div>
      <div ref="mapDiv" class="h-100 w-100 card" id="map"></div>
    </div>
  </div>
</template>
