<script setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex'
import { parse, compareAsc } from 'date-fns'
import { useFirebase } from '@/composables/firebase'
import * as XLSX from 'xlsx';
const store = useStore()

const { saveEventLog } = useFirebase()

const tipos = ref({
  alerta:{
    label: 'Alerta',
    value: true
  },
  sesion: {
    label: 'Sesión',
    value: true
  },
  exportar: {
    label: 'Exportar',
    value: true
  },
})

const sessions = computed(() => {
  return store.state.sessionsStore.sessions
  .filter(evento => {
    return tipos.value[evento.tipo]?.value
  }).sort((a,b) => {
    const dateA = parse(a.fecha, 'dd/MM/yyyy HH:mm', new Date())
    const dateB = parse(b.fecha, 'dd/MM/yyyy HH:mm', new Date())
    return compareAsc(dateB, dateA)
  })
})
const { setAlertModal } = inject('alertModal')

const exportExcel = () => {
 // Crear una hoja de cálculo
 const worksheet = XLSX.utils.aoa_to_sheet([]);

// Obtener la fecha y hora actual
const currentDate = new Date();
const formattedDate = currentDate.toLocaleString();

// Agregar el nombre del usuario, fecha y hora
XLSX.utils.sheet_add_aoa(worksheet, [
  [`Usuario: ${store.state.userStore.name}`],
  [`Fecha y hora: ${formattedDate}`],
  [''] // Línea en blanco para separar
], { origin: 'A1' });

// Convertir las alertas a una hoja y agregarlas debajo de la información del usuario
XLSX.utils.json_to_sheet(sessions.value);
XLSX.utils.sheet_add_json(worksheet, sessions.value, { origin: 'A4' });

// Crear un nuevo libro y agregar la hoja
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte de Eventos');

  XLSX.writeFile(workbook, `reporte-eventos-${new Date().getTime()}.xlsx`);
  saveEventLog('Exportó reporte de eventos', 'exportar')
}

</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex">
        <h6>Reporte de sesiones</h6>
        <span class="badge bg-success text-white mb-2 cursor-pointer ms-2" @click="exportExcel">
          <i class="fas fa-file-download"></i>
          Excel
        </span>
        <div class="ms-auto d-flex">
          <div class="form-check form-switch mx-2" v-for="(item, index) in tipos" :key="index">
            <input class="form-check-input" type="checkbox" :id="'evento-tipo'+index" checked v-model="item.value">
            <label class="form-check-label" :for="'evento-tipo'+index">{{item.label}}</label>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0 px-4">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Nombres
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Rol
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Fecha
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                tipo
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Evento
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              </th>
            </tr>
          </thead>
          <tbody v-if="sessions?.length">
            <tr v-for="item in sessions" :key="item.id">
              <td class="align-middle ">
                <span class="text-secondary text-xs font-weight-bold">{{ item.nombre }}</span>
              </td>
              <td class="align-middle ">
                <span class="text-secondary text-xs font-weight-bold">{{ item.rol }}</span>
              </td>
              <td class="align-middle ">
                <span class="text-secondary text-xs font-weight-bold">{{ item.fecha }}</span>
              </td>
              <td class="align-middle ">
                <span class="text-secondary text-xs font-weight-bold">{{ tipos[item.tipo].label }}</span>
              </td>
              <td class="align-middle ">
                <span class="text-secondary text-xs font-weight-bold">{{ item.evento }}</span>
              </td>
              <td class="align-middle text-center">
                <i class="fa fa-eye cursor-pointer" aria-hidden="true" @click="() => setAlertModal(item.alerta)"
                  data-bs-toggle="modal" data-bs-target="#alertModal" v-if="item?.alerta"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
