<script setup>
import { computed, ref, watch, inject } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import alertTypes from '@/constants/alertTypes'
import { normalizeText } from '@/utils'
import { format } from "date-fns";
import { useFirebase } from '@/composables/firebase'
const {setAlertPosition}= inject('alertCoords')
const router = useRouter()
// import bootstrap from "bootstrap/dist/js/bootstrap";

const { getAlertsByDate, getSessions, getOpenAlerts } = useFirebase()
const silenceNotif = ref(true)

const showMenu = ref(false);
const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const isLocationChanged = ref(false)

const {setAlertModal}= inject('alertModal')

const range = ref({
  start: new Date(),
  end: new Date(),
});

const notifications = ref([])

const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

const alerts = computed(() => {
  const alerts = store.state.alertsStore.alerts
  if (!alerts) return []
  const ids = Object.keys(alerts)
  return ids
})

watch(alerts, (newAlerts, oldAlerts) => {
  if (isLocationChanged.value) {
    isLocationChanged.value = false
    return
  }
  if (!oldAlerts || !oldAlerts.length) return []
  const news = newAlerts.filter(alert => !oldAlerts.includes(alert))
  const alerts = store.state.alertsStore.alerts
  notifications.value = news.map(item => ({ ...alerts[item], type: alertTypes.find(type => normalizeText(alerts[item].codigo) == normalizeText(type.label)) }))
  if (notifications.value.length) {
    var audio = document.getElementById("notification");
    if (silenceNotif.value) {
      audio.play()
    }
    const runNotification = () => {
      // const alertModal = new bootstrap.Modal(document.getElementById('alertModal'))
      setAlertModal(Object.values(notifications.value)[0].id)
      showMenu.value = true
      // alertModal.show()
      if (Object.values(notifications.value).length === 1) {
          new Notification(Object.values(notifications.value)[0].codigo, {
            body: Object.values(notifications.value)[0].lugar,
            icon: Object.values(notifications.value)[0].icon
          });
          return
        }
        new Notification(notifications.value.length + " Nuevas alertas registradas", {
          body: 'Se han registrado nuevas alertas',
          icon: '/favicon.png'
        });
    }
    if (Notification.permission === 'granted') {
      runNotification()
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          runNotification()
        }
      });
    }
  }
})

watch(range, (newDate, oldDate) => {
  if (newDate.start !== oldDate.start || newDate.end !== oldDate.end) {
    getAlertsByDate(range.value.start, range.value.end)
    getSessions(range.value.start, range.value.end)
    getOpenAlerts(range.value.start, range.value.end)
  }
})

watch(silenceNotif, (newDate) => {
  if (!newDate) {
    var audio = document.getElementById("notification");
    audio.pause()
  }
})

const changeLocation = (location) => {
  store.commit('setAlertsLocation', location)
  getAlertsByDate(range.value.start, range.value.end)
  getOpenAlerts(range.value.start, range.value.end)
  isLocationChanged.value = true
}

const areasList = computed(() => {
  const areas = store.state.areasStore.areas
  const ciudades = store.state.userStore.locations ? store.state.userStore.locations.filter(item => !areas.map(area => area.id).includes(item)).map(item => ({
    id: item,
    nombre: item
  })) : []
  return [
    ...areas,
    ...ciudades
  ]
})

const setAlertPositionMethod = (item)=>{
  setAlertPosition(item)
  closeMenu()
  router.push('/map')
}
</script>
<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''" v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      <div class="dropdown" v-if="$store.state.userStore.idToken?.claims?.role == 'ADMIN'">
        <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          {{ areasList.find(item => store.state.userStore.alertsLocation == item.id)?.nombre }}
        </button>
        <ul class="dropdown-menu overflow-auto" aria-labelledby="dropdownMenuButton1" style="max-height: 500px">
          <!-- <li @click="changeLocation('all')"><a class="dropdown-item" href="#">Todas las áreas</a></li> -->
          <li v-for="(item, index) in areasList" :key="index" @click="changeLocation(item.id)"><a class="dropdown-item"
              href="#">
              {{ item.nombre }}
            </a></li>
        </ul>
      </div>
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <div class="btn-group">
            <button class="btn btn-outline-dark dropdown-toggle btn-sm m-0" type="button"
              id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="false">
              {{ `${format(range.start, 'dd/MM/yyyy')} - ${format(range.end, 'dd/MM/yyyy')}` }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
              <VDatePicker v-model.range="range" mode="date" locale="es" />
            </div>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center text-dark me-2">
            {{ store.state.userStore.name }}
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center me-2">
            <a href="#" @click="minimizeSidebar" class="p-0 nav-link text-dark" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>

          <li class="nav-item dropdown d-flex align-items-center" :class="isRTL ? 'ps-2' : 'pe-2'">
            <a href="#" class="p-0 nav-link text-white position-relative" :class="[showMenu ? 'show' : '']"
              id="notiDropdown" data-bs-toggle="dropdown" aria-expanded="false" @blur="closeMenu">
              <i class="cursor-pointer fa fa-bell text-dark"></i>
              <div class="notification_badge" v-if="notifications.length"></div>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="notiDropdown">
              <li class="mb-2">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="silenceNotif">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Activar sonido</label>
                </div>
                <hr />
              </li>
              <li class="mb-2" v-for="(item) in notifications" :key="item.id">
                <div class="dropdown-item border-radius-md" @click="setAlertPositionMethod(item)">
                  <div class="py-1 d-flex">
                    <div class="my-auto me-2">
                      <img :src="item.type.icon" class="img-fluid " alt="user image"
                        style="min-width: 30px; width: 30px;" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        {{ item.codigo }}
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-map-marker me-1"></i>
                        {{ item.lugar }}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</template>
