<template>
  <div class="position-relative h-100">
    <div class="py-4 container-fluid" style="height: calc(100% - 4rem) !important;">
      <div class="card h-100 w-100 p-3">
        <div ref="mapDiv" class="h-100 w-100 card" id="map-canvas"></div>
      </div>
    </div>
    <div class="card areas_controls p-3 shadow-lg">
      <!-- <div class="coordinates" v-show="coordinatesVisible">
      <ul>
        <li v-for="(coord, index) in coordsList" :key="index">
          ({{ coord.lat }}, {{ coord.lng }})
        </li>
      </ul>
    </div> -->
      <!-- <button class="new-shape" @click="toggleNewShape">New Shape</button> -->
      <form @submit.prevent="saveArea">
        <select class="form-select mb-3" aria-label="Default select example" v-model="selectedArea"
          @change="selectArea">
          <option value="" disabled>Selecciona una área</option>
          <option :value="area.id" v-for="(area) in areas" :key="area.id">{{ area.nombre }}</option>
          <option value="nueva">Crear nueva área</option>
        </select>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="show-all-areas" v-model="showAll" @change="setShowAll">
          <label class="form-check-label" for="show-all-areas">Mostrar todas las Áreas</label>
        </div>
        <hr>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Nombre</label>
          <input type="text" class="form-control" id="nombre" placeholder="Ingrese un nombre" v-model="areaName">
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Celular</label>
          <input type="number" class="form-control" id="phone" placeholder="Ingrese un Nro de celular" v-model="phone">
        </div>
        <div class="justify-content-center d-flex gap-2" role="group" aria-label="Basic example">
          <button type="submit" class="btn btn-primary w-100" :disabled="!areaName || loading">Guardar</button>
          <button class="btn btn-danger" v-if="areaName" @click.prevent="toogleState"><i class="fas fa-trash"></i></button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade show" id="exampleModal" tabindex="-1" 
    style="display: block;background: rgba(0, 0, 0, 0.5);" aria-modal="true" role="dialog" v-if="toogleStateOpen">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Eliminar el área <b>{{ areaName }}</b>
          </h5>
        </div>
        <div class="modal-body">
          <h6 class="modal-title" id="exampleModalLabel">
            ¿Estás seguro de querer eliminar el área <b>{{ areaName }}</b>?
          </h6>
        </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toogleState">Cancelar</button>
            <button type="submit" class="btn btn-danger" @click="deleteAreaHandle" :disabled="loading">
              {{
                loading ? 'Eliminando...' : 'Eliminar'
              }}
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, toRaw } from 'vue';
import { useGoogleMaps } from '@/composables/google-maps'
import { useGeolocation } from '@/composables/geolocation'
import { useFirebase } from '@/composables/firebase'
import { useToast } from "vue-toastification";

const { coords } = useGeolocation()
const toast = useToast();
const { loader } = useGoogleMaps()
const { areas, createArea, updateArea, deleteArea } = useFirebase()
const selectedArea = ref('nueva')
const areaName = ref('')
const showAll = ref(false)
const phone = ref('')

const selectedAreaData = computed(() => {
  const area = areas.value.find(item => item.id == selectedArea.value)
  return area
})

const loading = ref(false)

const settings = {
  activeColor: '#05ab4d',
  lockedColor: '#ff0000',
};

let map;
// let shapeCreateListener;

const coordinatesVisible = ref(false);
const coordsList = ref([]);

const polygons = ref([]);
// const latestMarkers = ref([]);

const initializeMap = (center) => {
  const mapOptions = {
    zoom: 15,
    center: center,
    disableDefaultUI: true,
  };
  // eslint-disable-next-line no-undef
  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
  generateShape(generatePolygonCoordinates(center));
};

const generatePolygonCoordinates = (center) => {
  const lat = center.lat;
  const lng = center.lng;
  const offset = 0.001; // Offset for the shape size

  return [
    { lat: lat + offset, lng: lng + offset },
    { lat: lat + offset, lng: lng - offset },
    { lat: lat - offset, lng: lng - offset },
    { lat: lat - offset, lng: lng + offset },
  ];
};

watch(coords, (newCenter, oldCenter) => {
  if (!oldCenter.lat && !oldCenter.lng) {
    loader.value.load().then(() => {
      initializeMap(newCenter);
    });
  }
})

const clearAllShapes = () => {
  polygons.value.forEach(polygon => {
    // Clear all event listeners associated with the polygon
    // eslint-disable-next-line no-undef
    google.maps.event.clearInstanceListeners(polygon);
    // Remove the polygon from the map
    toRaw(polygon).setMap(null);
  });
  // Clear the array of polygons
  polygons.value = [];
};

const selectArea = () => {
  if(selectedArea.value === 'nueva'){
    const center = map.getCenter()
    areaName.value = ''
    generateShape(generatePolygonCoordinates(center.toJSON()));
    return 
  }
  if(showAll.value){
    areaName.value = selectedAreaData.value.nombre
    togglePolygonActive(selectedAreaData.value.id)
    const area = selectedAreaData.value.area
    areaName.value = selectedAreaData.value.nombre
    const formated = formatCoords(area)
    const center = calculateCenter(formated);
    map.setCenter(center);
    return
  }
  clearAllShapes()
  if (selectedAreaData.value) {
    const area = selectedAreaData.value.area
    areaName.value = selectedAreaData.value.nombre
    const formated = formatCoords(area)
    const center = calculateCenter(formated);
    map.setCenter(center);
    generateShape(formated)
  }else{
    clearAllShapes()
  }
}

const calculateCenter = (coordinates) => {
  let latSum = 0;
  let lngSum = 0;
  const len = coordinates.length;

  coordinates.forEach(coord => {
    latSum += coord.lat;
    lngSum += coord.lng;
  });

  return { lat: latSum / len, lng: lngSum / len };
};

const formatCoords = (area) => {
  const coords = area.split(' ')
  const formated = coords.map(coord => coord.replace(/[{()}]/g, '').split(',')).map(item => ({ lat: parseFloat(item[0]), lng: parseFloat(item[1]) }))
  return formated
}

const disableActivePolygon = (polygon) => {
  polygon = polygon || polygons.value.find(p => p.actionable === true);
  if (polygon) {
    polygon.setEditable(false);
    polygon.setDraggable(false);
    polygon.actionable = false;
    polygon.setOptions({
      fillColor: settings.lockedColor,
      fillOpacity: 0.35,
      strokeColor: settings.lockedColor,
    });
  }
};

const enableActivePolygon = (polygon) => {
  polygon.setEditable(true);
  polygon.setDraggable(true);
  polygon.actionable = true;
  polygon.setOptions({
    fillColor: settings.activeColor,
    fillOpacity: 0.35,
    strokeColor: settings.activeColor,
  });
  // eslint-disable-next-line no-undef
  google.maps.event.trigger(polygon.getPath(), 'insert_at');
};

const polygonChanged = function () {
  const paths = this.getPaths ? this.getPaths() : this;
  const coords = paths.getArray();
  coordsList.value = coords.map(coord => ({ lat: coord.lat(), lng: coord.lng() }));
};

const togglePolygonActive = function () {
  let polygon = this
  if(selectedArea.value) {
    polygon = polygons.value.find(p => p.id === selectedArea.value);
  }

  const currentActive = polygons.value.find(p => p.actionable === true);
  if (currentActive && currentActive !== polygon) {
    disableActivePolygon();
  }
  const isActive = polygon.getDraggable();
  if (isActive) {
    disableActivePolygon(polygon);
    coordinatesVisible.value = false;
    selectedArea.value = ''
    areaName.value = ''
  } else {
    if(showAll.value){
      const id = polygon.id
      selectedArea.value = id
      areaName.value = selectedAreaData.value.nombre
    }
    enableActivePolygon(polygon);
    coordinatesVisible.value = true;
  }
};

const attachListeners = (polygon) => {
  const paths = polygon.getPath();
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(paths, 'set_at', polygonChanged);
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(paths, 'insert_at', polygonChanged);
  // eslint-disable-next-line no-undef
  google.maps.event.addListener(polygon, 'click', togglePolygonActive);
};

const generateShape = (coordinates, allShapes = false, id=null) => {
  // eslint-disable-next-line no-undef
  const polygon = new google.maps.Polygon({
    paths: coordinates,
    draggable: allShapes ? false : true,
    strokeColor: allShapes? settings.lockedColor :settings.activeColor,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    editable: allShapes ? false : true,
    fillColor: allShapes? settings.lockedColor :settings.activeColor,
    fillOpacity: 0.35,
    id: id
  });
  polygon.setMap(map);
  attachListeners(polygon);
  if(!allShapes){
    enableActivePolygon(polygon);
  }
  polygons.value.push(polygon);
};

const saveArea = () => {
  if (areaName.value && coordsList.value.length) {
    loading.value = true
    try {
      const formatedCoords = coordsList.value.map(coord => `(${coord.lat},${coord.lng})`).join(' ');
      if(selectedArea.value && selectedArea.value !== 'nueva'){
        updateArea(selectedArea.value, areaName.value, formatedCoords, phone.value)
        .then(resp => {
          toast.success(resp)
          loading.value = false
        })
      }else{
        createArea(areaName.value, formatedCoords, phone.value)
        .then(resp => {
          selectedArea.value = resp
          toast.success('Área de monitoreo creada exitosamente')
          loading.value = false
        })
      }
      
    } catch (error) {
      toast.error(error)
      loading.value = false
    }
  }
}

const deleteAreaHandle = () => {
  if (selectedArea.value) {
  try {
    
      loading.value = true
      deleteArea(selectedArea.value)
        .then(resp => {
          toast.success(resp)
          selectedArea.value = ''
          areaName.value = ''
          clearAllShapes()
          const center = map.getCenter()
          generateShape(generatePolygonCoordinates(center.toJSON()));
          loading.value = false
          toogleState()
        })
    
  } catch (error) {
    toast.error(error)
    loading.value = false
  }
}
}

const setShowAll = (e) => {
  if(e.target.checked){
    clearAllShapes()
    selectedArea.value = ''
    areas.value.forEach(item => {
      const area = formatCoords(item.area)
      generateShape(area, true, item.id);
    })
  }else{
    clearAllShapes()
    const center = map.getCenter()
    generateShape(generatePolygonCoordinates(center.toJSON()));
  }
}
const toogleStateOpen = ref(false)
const toogleState = ()=>{
  toogleStateOpen.value = !toogleStateOpen.value
}
</script>