<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import {computed, ref, onMounted, watch, toRaw} from 'vue'
import {useStore} from 'vuex'
import { Bar, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import alertTypes from '@/constants/alertTypes'
import {normalizeText} from '@/utils'
import { useGeolocation } from '@/composables/geolocation'
import { useGoogleMaps } from '@/composables/google-maps'

const {loader} = useGoogleMaps()

const map = ref(null)
const heatmap = ref(null)
const mapAlerSelected = ref('')
const { coords } = useGeolocation()

onMounted(() => {
  loader.value.load().then(async () => {
    // eslint-disable-next-line no-undef
    const { Map } = await google.maps.importLibrary("maps");

    map.value = new Map(document.getElementById('map'), {
      center: coords.value,
      zoom: 15,
      heading: 320,
      tilt: 47.5,
    });
  }).then(async ()=>{
      setDataHeatmap()
  })
})
watch(coords, (newCenter, oldCenter) => {
  if (map.value && (!oldCenter.lat && !oldCenter.lng)) {
    // eslint-disable-next-line no-undef
    map.value.setCenter(newCenter);
  }
})

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, ChartDataLabels)

const store = useStore()
const barsData = computed(() => {
  if(alertsAnalyticsByState.value){
    const labels = ['PENDIENTE', 'RECIBIDO', 'ATENDIDO', 'CANCELADO']
    const datasets = alertTypes.map(type => {
      const data = labels.map(item => {
        const alertObj = alertsAnalyticsByState.value[item].find(alert => normalizeText(alert.title) == normalizeText(type.label))
        return alertObj.value
      })
      return {
        label: type.label,
        backgroundColor: type.color,
        data
      }
    });
    return {
      labels,
      datasets
    }
  }
  return []
})

const barsOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
})

const donutOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
        datalabels: {
            formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(2)+"%";
                if(percentage == "0.00%"){
                    percentage = ""
                }
                return percentage;
            },
            color: '#fff',
        }
    }
})

const donutAtendido = computed(()=>{
  if(alertsAnalyticsByState.value){
  return {  labels: alertsAnalyticsByState.value['ATENDIDO'].map(item => item.title),
    datasets: [
      {
        backgroundColor: alertsAnalyticsByState.value['ATENDIDO'].map(item => item.color),
        data: alertsAnalyticsByState.value['ATENDIDO'].map(item => item.value)
      }
    ]}
  }
  return []
})

const donutTotal = computed(()=>{
  if(alertsAnalyticsByState.value){
  const flatted = Object.values(alertsAnalyticsByState.value).flat()
  const sameType = Object.groupBy(flatted, ({title}) => title)
  const sameTypeArr = Object.entries(sameType)

  const final = sameTypeArr.map((group) => {
    const initialValue = 0;
  const sumWithInitial = group[1].reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    initialValue,
  );
  return ({
    "title":group[0],
   "value":sumWithInitial,
   "icon":group[1][0].icon,
   "color":group[1][0].color
  })
    
  })
  return {  labels: final.map(item => item.title),
    datasets: [
      {
        backgroundColor: final.map(item => item.color),
        data:final.map(item => item.value)
      }
    ]}
  }
  return []
})

const alertAnalytics = computed(()=>{
  return store.getters.getAlertsAnalytics
})
const alertsAnalyticsByState = computed(()=>{
  return store.getters.getAlertsAnalyticsByState
})

const heatMapData = computed(()=>{
  return store.state.alertsStore.alertsByDate
  .filter(item => {
    if(mapAlerSelected.value){
      return normalizeText(item.codigo) == normalizeText(mapAlerSelected.value)
    }
    return item
  })
  .map(item => {
    // eslint-disable-next-line no-undef
    return new google.maps.LatLng(item.lat, item.lng)
  })
})

const setDataHeatmap = async () => {
  toRaw(heatmap.value)?.setMap(null);
  heatmap.value = null
  // eslint-disable-next-line no-undef
  const {HeatmapLayer} = await google.maps.importLibrary("visualization")
  heatmap.value = new HeatmapLayer({
    data: heatMapData.value
  });
  heatmap.value.setMap(map.value)
}

watch(heatMapData, () => {
  if (map.value) {
    setDataHeatmap()
  }
})

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row" v-if="alertAnalytics">
          <div class="col-lg-3 col-md-6 col-12" v-for="(item, index) in alertAnalytics" :key="index" >
            <mini-statistics-card
              :title="item.title"
              :value="item.value"
              :icon="{
                component: item.icon,
                background: 'nothing',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-12">
        <h6>Alertas generales</h6>
        <div class="card max-h-550px p-4">
          <Bar
            id="my-chart-id"
            :options="barsOptions"
            :data="barsData"
          />
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-6">
        <h6>Total de alertas</h6>
        <div class="card max-h-550px p-4">
          <Doughnut :data="donutTotal" :options="donutOptions" />
        </div>
      </div>
      <div class="col-6">
        <h6>Alertas Atendidas</h6>
        <div class="card max-h-550px p-4">
          <Doughnut :data="donutAtendido" :options="donutOptions" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-2">
          <div class="col">
            <h6>Mapa de calor</h6>
          </div>
          <div class="col-2">
            <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="mapAlerSelected">
              <option selected value="">Todas</option>
              <option :value="item.label" v-for="(item, index) in alertTypes" :key="index">{{ item.label }}</option>
            </select>
          </div>
        </div>
        <div class="card h-100 w-100 p-3 max-h-550px min-h-550px">
          <div id="map" class="h-100 w-100 card"></div>
        </div>
      </div>
    </div>
  </div>
</template>
