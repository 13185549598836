export default [
  {
    label: "Violencia o Bullyng",
    icon: "/img/punto_violencia.png",
    color: "#9A4DB4",
  },
  {
    label: "Accidente de tránsito",
    icon: "/img/punto_accidente.png",
    color: "#EDB30A",
  },
  {
    label: "Extorsión",
    icon: "/img/ic_extorsion.png",
    color: "#3a3a3a",
  },
  {
    label: "Robo-Asalto",
    icon: "/img/punto_robo.png",
    color: "#ED3237",
  },
  {
    label: "Robo vehículo",
    icon: "/img/punto_robovehiculo.png",
    color: "#E9582F",
  },
  {
    label: "Drogas",
    icon: "/img/markerpo_droga.png",
    color: "#009141",
  },
];
