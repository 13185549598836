<script setup>
import {computed, inject, ref} from 'vue'
import { useRoute, useRouter } from "vue-router";
import SidenavItem from "./SidenavItem.vue";
import {useFirebase} from '@/composables/firebase'
import { useStore } from 'vuex'
import alertTypes from '@/constants/alertTypes'
import {normalizeText} from '@/utils'
import StatusBadget from '@/components/StatusBadget'
import { parse, compareAsc } from 'date-fns'

const {logout} = useFirebase()
const router = useRouter()
const store = useStore()
const {setAlertPosition}= inject('alertCoords')
const {setAlertModal}= inject('alertModal')

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const signOut = async () => {
  await logout()
  router.push('/')
}

const alertFilters = ref([...alertTypes.map(item => ({label:item.label, value: true}))])

const alerts = computed(() => {
  return store.state.alertsStore.alerts.filter(item => {
    if(alertFilters.value){
      const filter = alertFilters.value.find(filterItem => {
        return normalizeText(filterItem.label) == normalizeText(item.codigo)
      })
      return filter
    }
    return item
  }).sort((a,b) => {
    const dateA = parse(a.fecha, 'dd/MM/yyyy HH:mm', new Date())
    const dateB = parse(b.fecha, 'dd/MM/yyyy HH:mm', new Date())
    return compareAsc(dateB, dateA)
  })
})

const setAlertPositionMethod = (item)=>{
  router.push('/map')
  setAlertPosition(item)
}

</script>
<template>
  <div
    class="collapse navbar-collapse w-auto"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav d-flex flex-column h-100">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="fas fa-chart-line text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/map"
          :class="getRoute() === 'map' ? 'active' : ''"
          navText="Mapa"
        >
          <template v-slot:icon>
            <i class="fas fa-map-marked-alt text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="$store.state.userStore.idToken?.claims?.role == 'ADMIN'">
        <sidenav-item
        to="/users"
          class=""
          navText="Usuarios"
          data-bs-toggle="collapse" data-bs-target="#collapseUser" aria-expanded="false" aria-controls="collapseUser"
        >
          <template v-slot:icon>
            <i class="fas fa-users text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
        <div class="collapse h-100 bg-gray-100" id="collapseUser">
          <div class="p-2 ">
            <sidenav-item
          to="/users"
          :class="getRoute() === 'users' ? 'active' : ''"
          navText="Usuarios"
        >
          <template v-slot:icon>
            <i class="fas fa-users text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
        <sidenav-item
          to="/sessions"
          :class="getRoute() === 'sessions' ? 'active' : ''"
          navText="Historial de eventos"
        >
          <template v-slot:icon>
            <i class="fas fa-history text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
          </div>
        </div>
      </li>
      <li class="nav-item" v-if="$store.state.userStore.idToken?.claims?.role == 'ADMIN'">
        <sidenav-item
          to="/areas"
          :class="getRoute() === 'areas' ? 'active' : ''"
          navText="Áreas de monitoreo"
        >
          <template v-slot:icon>
            <i class="fas fa-draw-polygon text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          to="#"
          navText="Alertas"
          :class="'normal'"
          data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
        >
          <template v-slot:icon>
            <i class="fa fa-exclamation text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
        <div class="collapse show h-100 bg-gray-100" id="collapseExample">
          <div class="p-2 ">
            <router-link to="/alerts" class="nav-link">
              <i class="fa fa-table text-success text-sm opacity-10" aria-hidden="true"></i>
              Reporte de alertas
            </router-link>
            <hr>
            <span class="badge bg-light text-dark mb-2 cursor-pointer" data-bs-toggle="modal" data-bs-target="#sidebarFiltersModal">
              <i class="ni ni-ui-04"></i>
              Filtros
            </span>
            <div class="card p-3 mb-2" v-for="(item) in alerts" :key="item.id">
              <div class="card-header p-0">
                <div class="row m-0">
                  <div class="col-2 p-0">
                    <img :src="alertTypes.find(type => normalizeText(item.codigo) == normalizeText(type.label)).icon" alt="" class="img-fluid">
                  </div>
                  <div class="col-10 text-truncate">
                    {{ item.codigo }}
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul>
                  <li v-if="item.fecha"><small class="text-truncate d-block">
                    <b>Fecha:</b> {{item.fecha}}
                  </small></li>
                  <li v-if="item.dato"><small class="text-truncate d-block">
                    <b>Descripción:</b> {{item.dato}}
                  </small></li>
                  <li v-if="item.nombre"><small class="text-truncate d-block">
                    <b>Nombre:</b> {{item.nombre}}
                  </small></li>
                  <li v-if="item.telefono"><small class="text-truncate d-block">
                    <b>Celular:</b> {{item.telefono}}
                  </small></li>
                  <li v-if="item.estado"><small class="text-truncate d-block">
                    <b>Estado:</b> <StatusBadget :estado="item.estado" :id="item.id" :readOnly="true"/>
                  </small></li>
                </ul>
                <hr/>
                <div class="row mt-2">
                  <div class="col-6 d-flex justify-content-center">
                    <i class="fa fa-eye cursor-pointer" aria-hidden="true" @click="()=>setAlertModal(item.id)" data-bs-toggle="modal" data-bs-target="#alertModal"></i>
                  </div>
                  <div class="col-6 d-flex justify-content-center">
                    <i class="fa fa-crosshairs cursor-pointer" aria-hidden="true" @click="setAlertPositionMethod(item)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="mt-3 nav-item px-3 mt-auto">
        <hr>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="#"
          class="text-danger"
          navText="Cerrar sesión"
          @click="signOut"
        >
          <template v-slot:icon>
            <i class="fa fa-sign-out text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
        
      </li>
    </ul>
  </div>
  
  <div class="modal fade" id="sidebarFiltersModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filtro de alertas</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-check form-switch" v-for="(item, index) in alertFilters" :key="index">
          <input class="form-check-input" type="checkbox" :id="'alert'+index" checked v-model="item.value">
          <label class="form-check-label" :for="'alert'+index">{{item.label}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Aplicar</button>
      </div>
    </div>
  </div>
</div>
</template>
