const API_URL = 'http://api.mipar.org/api'

export default {
    getUsers({state}){
        return new Promise((resolve, reject) => {
            fetch(API_URL+'/users', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': state.accessToken
                }
            })
            .then(res => res.json())
            .then(data => {
                resolve(data)
            })
            .catch(err => reject(err))
        })
    },
    createUser({state}, data){
        return new Promise((resolve, reject) => {
            fetch(API_URL+'/users', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': state.accessToken
                },
                body: JSON.stringify(data),
                method: 'POST'
            })
            .then(res => res.json())
            .then(data => {
                resolve(data)
            })
            .catch(err => reject(err))
        })
    },
    updateUser({state}, data){
        return new Promise((resolve, reject) => {
            fetch(API_URL+'/users/'+data.uid, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': state.accessToken
                },
                body: JSON.stringify(data),
                method: 'PUT'
            })
            .then(res => res.json())
            .then(data => {
                resolve(data)
            })
            .catch(err => reject(err))
        })
    },
    toggleState({state}, data){
        return new Promise((resolve, reject) => {
            fetch(API_URL+'/users/'+data.uid+`/${data.state ? 'enable':'disable'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': state.accessToken
                },
                method: 'PUT'
            })
            .then(res => res.json())
            .then(data => {
                resolve(data)
            })
            .catch(err => reject(err))
        })
    }
}