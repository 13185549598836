<script setup>
import { computed, onMounted, ref, provide } from "vue";
import { useStore } from "vuex";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import StatusBadget from '@/components/StatusBadget'
import { useInactivityLogout } from './composables/useInactivityLogout';

const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
const alertCoords = ref(null)
const activateAudio = ref(false)
const { isWarningVisible } = useInactivityLogout(1800000); 
const setAlertPosition = (coords) => {
  alertCoords.value = coords
}

provide('alertCoords', {
  alertCoords,
  setAlertPosition
})

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});

onMounted(()=>{
  const context = new AudioContext();
  if(context.state == 'suspended' || context.state == 'closed'){
    activateAudio.value = true
  }
  Notification.requestPermission().then((result) => {
    console.log(result);
  });
})

const runAudio = () => {
  const context = new AudioContext();
  context.resume().then(() => {
    console.log(context.state);
    activateAudio.value = false
  });
}

const alertId = ref(null)
const alertModalItem = computed(()=>{
  if(alertId.value) return store.state.alertsStore.alertsByDate.find(item => item.id == alertId.value)
  return null
})
const setAlertModal = (id) => {
  alertId.value = id 
}
provide('alertModal', {setAlertModal})
</script>
<template>
  <audio src="/audios/notification.mp3" id="notification">
    Your browser does not support the <code>audio</code> element.
  </audio>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>

  <sidenav v-if="showSidenav" />

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    style="height: 100vh !important;"
  >
    <!-- nav -->

    <navbar :class="[navClasses]" v-if="showNavbar" />

    <router-view />

    <app-footer v-show="showFooter" />

 
  </main>

  <div class="modal fade" id="alertModal" tabindex="-1" aria-labelledby="alertModalLabel" aria-hidden="true">
  <div class="modal-dialog" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="alertModalLabel" v-if="alertModalItem?.codigo">{{alertModalItem.codigo}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ul>
          <li v-if="alertModalItem?.lugar">
            <b>Dirección:</b> {{alertModalItem.lugar}}
          </li>
          <li v-if="alertModalItem?.fecha">
            <b>Fecha:</b> {{alertModalItem.fecha}}
          </li>
          <li v-if="alertModalItem?.dato">
            <b>Descripción:</b> {{alertModalItem.dato}}
          </li>
          <li v-if="alertModalItem?.nombre">
            <b>Nombre:</b> {{alertModalItem.nombre}}
          </li>
          <li v-if="alertModalItem?.telefono">
            <b>Celular:</b> {{alertModalItem.telefono}}
          </li>
          <li v-if="alertModalItem?.estado">
            <b>Estado:</b> <StatusBadget :estado="alertModalItem.estado" :id="alertModalItem.id"/>
          </li>
        </ul>
        <img v-if="alertModalItem?.imagen && alertModalItem.imagen !== 'SIN-IMAGEN'" :src="alertModalItem.imagen" class="img-fluid" alt="">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal_activate_sound" v-if="activateAudio">
  <div class="card content">
    <div class="sound_off">
      <i class="fas fa-volume-mute"></i>
    </div>
    <p>La reproducción automática de audio está desactivada, haz click en el siguiente botón para activarla</p>
    <button class="btn btn-success mb-0" @click="runAudio">Activar sonido <i class="fas fa-volume-up"></i></button>
  </div>
</div>
<div v-if="isWarningVisible" class="session-warning">
  <p>Tu sesión se cerrará en 1 minuto por inactividad.</p>
</div>
</template>

<style scoped>
.session-warning {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffcc00;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  color: #000;
}
</style>
